import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './feature/home/home.component';
import { ContactComponent } from './feature/contact/contact.component';
import { ImprintComponent } from './feature/imprint/imprint.component';
import { PrivacyComponent } from './feature/privacy/privacy.component';
import { WholepageComponent } from './feature/wholepage/wholepage.component';

const routes: Routes = [
  {
    path: '',
    component: WholepageComponent
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'impressum',
    component: ImprintComponent
  },
  {
    path: 'datenschutz',
    component: PrivacyComponent
  },
  {
    path: 'kontakt',
    component: ContactComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() title: string;
  openContent: boolean;

  constructor() {
    this.openContent = false;
  }

  ngOnInit() {
  }

  setOpenContent(): void {
    this.openContent = !this.openContent;
  }

}

export type Stack = Technology | IDE;

export enum Technology {
  ANGULAR = 'Angular',
  ANGULARJS = 'AngularJS',
  JAVASCRIPT = 'JavaScript',
  TYPESCRIPT = 'TypeScript',
  NODEJS = 'Node.js',
  HTML = 'HTML',
  CSS = 'CSS',
  SASS = 'Sass',
  GIT = 'git'
}

export enum IDE {
  VISUAL_STUDIO_CODE = 'Visual Studio Code',
  ATOM = 'Atom',
  INTELLIJ_IDEA = 'IntelliJ IDEA'
}

export const setTechnologiesAngular = [
  Technology.ANGULAR,
  Technology.TYPESCRIPT,
  Technology.JAVASCRIPT,
  Technology.HTML,
  Technology.CSS,
  Technology.SASS,
  Technology.GIT
];

export const setTechnologiesAngularJS = [
  Technology.ANGULARJS,
  Technology.JAVASCRIPT,
  Technology.HTML,
  Technology.CSS,
  Technology.GIT
];

export const setTechnologiesAngularAngularJS = [
  Technology.ANGULAR,
  Technology.ANGULARJS,
  Technology.TYPESCRIPT,
  Technology.JAVASCRIPT,
  Technology.HTML,
  Technology.CSS,
  Technology.SASS,
  Technology.GIT
];

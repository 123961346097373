import { Component, OnInit, Input } from '@angular/core';
import { Stack } from '../../core/interfaces/technologies.model';

@Component({
  selector: 'app-card-project',
  templateUrl: './card-project.component.html',
  styleUrls: ['./card-project.component.scss']
})
export class CardProjectComponent implements OnInit {
  @Input() title: string;
  @Input() date: string;
  @Input() stack: Array<Stack>;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-scrolling-mouse',
  templateUrl: './scrolling-mouse.component.html',
  styleUrls: ['./scrolling-mouse.component.scss']
})
export class ScrollingMouseComponent implements OnInit {
  @Input() content: string;

  constructor() { }

  ngOnInit() {
  }

}

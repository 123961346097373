import { Stack, setTechnologiesAngular } from './../../core/interfaces/technologies.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  setTechnologiesAngular: Array<Stack>;

  constructor() { }

  ngOnInit() {
    this.setTechnologiesAngular = setTechnologiesAngular;
  }

}

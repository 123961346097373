import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CardComponent } from './card/card.component';
import { ScrollingMouseComponent } from './scrolling-mouse/scrolling-mouse.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ImprintComponent } from './imprint/imprint.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { JobComponent } from './job/job.component';
import { ProjectComponent } from './project/project.component';
import { WholepageComponent } from './wholepage/wholepage.component';
import { CardProjectComponent } from './card-project/card-project.component';
import { CardInfoComponent } from './card-info/card-info.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CardComponent,
    ScrollingMouseComponent,
    LeistungenComponent,
    ContactComponent,
    PrivacyComponent,
    ImprintComponent,
    AboutUsComponent,
    JobComponent,
    ProjectComponent,
    WholepageComponent,
    CardProjectComponent,
    CardInfoComponent
  ],
  imports: [
    CommonModule,
    ScrollToModule.forRoot()
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    WholepageComponent
  ]
})
export class FeatureModule { }

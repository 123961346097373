import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wholepage',
  templateUrl: './wholepage.component.html',
  styleUrls: ['./wholepage.component.scss']
})
export class WholepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
